.split {
    height: 100%;
    width: 13%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 100px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.left {
    left: 0;
}

.right {
    right: 0;
    margin-left: 13%;
}

.centered {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: -webkit-fill-available;
}

.report-container {
    position: absolute;
    left: 45%;
    margin-top: 400px;
    text-align: center;
    width: 40vmin;
}

.side-items {
    margin-top: 55px;
}

.side-links {
    height: 48px;
    padding-left: 25px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #545050;
    font: 500 14px Lato;
    border-left: 4px solid transparent;
    border-bottom: 2px solid rgba(236, 105, 26, 0.05);
}

.side-title {
    height: 45px;
    display: flex;
    align-items: center;
    align-content: center;
    text-decoration: none;
    font: 800 18px Lato;
    border-left: 4px solid transparent;
    color: #ed7d41e0;
}

.side-links:hover {
    background-color: rgba(236, 105, 26, 0.05);
}

.report-item {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
}

.side-links.active {
    color: #ed7d41e0;
    font: 540 14px Lato;
    background: rgba(236, 105, 26, 0.05);
    border-left: 4px solid #ed7d41e0;
}

@media screen and (max-width: 1040px) {

    .split {
        height: 100%;
        width: 30%;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow-x: hidden;
        padding-top: 120px;
    }

    .right {
        right: 0;
        margin-left: 30%;
    }

    .Embed-container-split {
        display: block;
        position: absolute;
        height: auto;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: 60px 0 4px 30%;
        background-color: #f5f5f5;
    }

    .powerbi-container {
        background-color: #f5f5f5;
    }

    .side-links {
        height: 60px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #545050;
        font: 300 12px Lato;
        border-left: 4px solid transparent;
        border-bottom: 2px solid rgba(236, 105, 26, 0.05);
    }

    .side-links.active {
        color: #ed7d41e0;
        font: 500 12px Lato;
        background: rgba(236, 105, 26, 0.05);
        border-left: 4px solid #ed7d41e0;
    }


}
