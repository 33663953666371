.navbar {
    background: linear-gradient(90deg, rgb(88 86 86 / 20%) 0%, rgb(21 19 19) 100%);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    /*grid-gap: 12px;*/
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item {
    display: flex;
    align-items: center;
    height: 60px;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.nav-links.active {
    border-bottom: 2px solid #fff;
    transition: all 0.1s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.logo-container {
  display: inline-flex;
  align-items: center;
}

.trackman-logo {
  vertical-align: middle;
}

.logo-text {
  margin-left: 8px;
  font-size: 2rem;
  color: #fff;
}


@media screen and (max-width: 1024px) {

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 60vh;
        position: relative;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        /*padding: 1rem;*/
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

}
