* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Lato;
    -ms-overflow-style: none;
}

.home {
    position: relative;
    height: 100vh;
    background-image: url('/images/home.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.home-title {
    font-size: 3vw;
    color: rgba(255, 255, 255, 0.68);
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}


.Embed-container {
    display: block;
    position: absolute;
    height: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 60px 0 10px;
    background-color: #f5f5f5;
}

.Embed-container-split {
    display: block;
    position: absolute;
    height: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: 60px 0 4px 13%;
    overflow: hidden;
}

.powerbi-container {
    background-color: #f5f5f5;
}

.content {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
}
