.cards {
    padding: 1rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-height: calc(100vh - 60px - 70px); /* Adjust for navbar and search bar heights */
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.cards-container::-webkit-scrollbar {
    display: none; /* WebKit browsers */
}


h1 {
    text-align: center;
}

.cards__container {
    display: flex;
    flex-flow: column;
    width: 90%;
    margin: 25px auto;
}

.cards__wrapper {
    position: relative;
    margin: 45px 0 45px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 25rem));
    gap: 1rem;
    justify-content: center;
}

.cards__items {
    margin-bottom: 24px;
}

.cards__item {
    display: flex;
    margin: 0 1rem;
    border-radius: 20px;
    transition: transform 200ms ease-in;
}

.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(118, 122, 128, 0.3);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.030));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.030));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__link__exclude {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(118, 122, 128, 0.3);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.030));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.030));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    background-color: #e4e4e4c2;
}

.cards__item:hover {
    transform: scale(1.05);
}

.cards__item__info {
    padding: 20px 30px 30px;
}

.cards__item__title {
    color: #8a8585;
    font-size: 20px;
    line-height: 27px;
    border-bottom: 2px solid #8a8585;
    margin-bottom: 10px;
}

.cards__item__subtitle {
    color: #8a8585;
    font-size: 16px;
    line-height: 24px;
    border-bottom: #8a8585;
    margin-top: 10px;
}

.cards__item__text {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
}

.divider {
    color: #8a8585;
    font: 500 20px Lato;
}

.empty_cards_page {
    color: #8a8585;
    font: 500 20px Lato;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.cards__item__right {
    float: right;
    bottom: 0;
    font: 500 15px Lato;
    background-color: rgb(236 236 239 / 68%);
    color: #8a8585;
    border: 1px solid rgb(236 236 239 / 68%);
    padding: 5px;
    border-radius: 20%;
}

@media only screen and (min-width: 1200px) {
    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__container {
        display: flex;
        flex-flow: column;
        width: 100%;
        margin: 15px auto;
    }

    .cards__wrapper {
        position: relative;
        margin: 50px 0 45px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 30rem));
        gap: 1rem;
        justify-content: center;
    }

    .empty_cards_page {
        color: #8a8585;
        font: 500 20px Lato;
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
    }
}