.dropdown-menu {
    width: 200px;
    position: absolute;
    top: 60px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background: linear-gradient(75deg, rgb(88 86 86 / 80%) 0%, rgb(76, 71, 71) 100%);
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: #f4f1f1;
    color: black;
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}

.dropdown-link.active {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}

.dropdown-link:hover {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: black;
    padding: 16px;
}

@media screen and (max-width: 1024px) {
    .dropdown-menu {
        width: 100%;
        position: absolute;
        top: 170px;
        list-style: none;
        text-align: center;
    }
}