.search {
    margin: 10px 45px;;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*float: right;*/
}

.searchInputs {
    margin: 15px 0 0 45px;
    display: flex;
    float: right;
}

.search input {
    background-color: white;
    border: 1px solid rgb(236 236 239 / 68%);
    font-size: 15px;
    padding: 15px;
    height: 30px;
    width: 170px;
    border-radius: 8px;
}

.searchIcon {
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;
}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 30px;
}

.input {
    border: 0;
}

#clearBtn {
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .search {
        margin: 10px 45px;
        display: flex;
        float: right;
    }
}